import React, { useState, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import {
  useInView,
  InView,
  ObserverInstanceCallback,
} from 'react-intersection-observer';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Container from '@/components/common/container';
import Fade from '@/components/common/fade';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { PortableText } from '@portabletext/react';
import CtaLink from '@/components/common/cta-link';
import GradientText from '@/components/common/gradient-text';
// import TextLoop from 'react-text-loop';
import Lottie from 'react-lottie';
import * as heroAnimationData from '@/assets/hero-animation.json';
import DashedArrowRight from '@/assets/dashed-arrow-right.svg';
// import PlayButton from '@/assets/play-btn.svg';
import Graphic from '@/assets/graphic.svg';
import Tag from '@/components/common/tag';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';
import { trackEvent } from '@/utils/amplitude-tracking';
import Popup from '@/components/common/v4/popup';
import Logo from '@/assets/logo-w-full.svg';
import { smoothScrollTo } from '../../common/smooth-scroll';

const VideoTag = ({ url, webm }) => {
  const [isClicked, setIsClicked] = useState(false);
  const videoRef = useRef(null);

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: [0.1, 0.25, 0.5, 1],
    rootMargin: '0px 0px 0px 0px',
    onChange: (inView) => {
      if (!inView && isClicked) {
        videoRef.current.pause();
      }
    },
  });

  const playPauseVideo = () => {
    videoRef.current.paused
      ? videoRef.current.play()
      : videoRef.current.pause();
  };

  const onVideoClick = () => {
    setIsClicked(true);
    videoRef.current.controls = true;
    videoRef.current.muted = false;
    videoRef.current.loop = false;
    videoRef.current.currentTime = 0;
    videoRef.current.play();
  };

  useEffect(() => {
    if (!isClicked) {
      videoRef.current.currentTime = 7;

      // if browser is chrome remove controls

      if (navigator.userAgent.indexOf('Chrome') > -1) {
        videoRef.current.controls = false;
        videoRef.current.loop = true;
      }
    }
  }, []);

  return (
    <div className="relative rounded-lg" ref={ref}>
      {!isClicked && (
        <div
          onClick={onVideoClick}
          className="absolute inset-0 z-10 flex cursor-pointer items-center justify-center duration-500 hover:opacity-80"
        >
          <div className={`h-20 w-20 lg:h-28 lg:w-28`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 98 98"
              fill="none"
              className="w-full"
            >
              <rect
                x="0.366699"
                y="0.640381"
                width="97.266"
                height="97.266"
                rx="48.633"
                fill="#4A2FF9"
              />
              <path
                d="M71.6953 49.2057L37.4749 69.4224L37.4749 28.9891L71.6953 49.2057Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      )}

      <video
        className="w-full rounded-lg"
        ref={videoRef}
        autoPlay
        muted
        playsInline
        loop
        preload="metadata"
      >
        <source src={`${url}?cache=break`} type="video/mp4" />
        <img src="/og-image.png" alt="Try LLM Studio" />
      </video>
    </div>
  );
};

const VideoIframe = ({ url }) => {
  return (
    <iframe
      style={{
        position: 'absolute',
        margin: '0 auto',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
      width="100%"
      height="100%"
      src={`${url}?cache=break&autoplay=1`}
      webkitAllowFullScreen={true}
      mozAllowFullScreen={true}
      allowFullScreen={true}
    />
  );
};

const VideoWrap = ({ children }) => {
  return (
    <div
      style={{
        position: 'relative',
      }}
      className="animate block animate-loading overflow-hidden rounded-lg bg-gradient-to-r from-transparent via-purpley/10 to-transparent bg-[length:200%_100%] before:absolute before:content-['']"
    >
      {children}
    </div>
  );
};

const VideoItem = ({ url }) => {
  if (url.indexOf('youtube') > 0) {
    return <VideoIframe url={url} />;
  }

  return <VideoTag url={url} />;
};

export const titlePortableText = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <span className="title-hl">{children}</span>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const Hero = ({ hero }) => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    console.log('OPEN');
    setPopupOpen(true);
  };

  const closePopup = () => {
    console.log('CLOSE');
    setPopupOpen(false);
  };

  const [hasHeroBanner] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 1,
    rootMargin: '0px 200px 0px 200px',
    // onChange: (inView) => {
    //   if (inView && !isClicked) {
    //     setSelectedIndex(idx);
    //   }
    // },
  });

  const {
    ctaLinksV2,
    leftBottomImage,
    leftMidImage,
    leftTopImage,
    rightBottomImage,
    rightMidImage,
    rightTopImage,
    animationItems,
    heroVideo,
    heroVideoWebm,
    heroClickableImage,
  } = hero;

  const subTitlePortableText = {
    block: {
      normal: ({ children }) => <span>{children}</span>,
    },
    marks: {
      highlight: ({ children, value }) => (
        <TextLoop animationItems={animationItems} />
      ),
      link: ({ children, value }) => {
        const rel = !value.href.startsWith('/')
          ? 'noreferrer noopener'
          : undefined;
        return (
          <a href={value.href} rel={rel}>
            {children}
          </a>
        );
      },
    },
  };

  return (
    <div className="mx-auto w-full max-w-[1440px] px-4 sm:px-10 lg:px-20 lg:px-4">
      <div className="relative mx-auto w-full max-w-1720">
        <div
          className={` ${
            hasHeroBanner
              ? 'pb-12 pt-6 md:pb-24 md:pt-12 lg:pb-28 lg:pt-16 xl:pb-32 xl:pt-20 2xl:pb-32 2xl:pt-24'
              : 'flex flex-col gap-8 py-12 md:flex-row md:py-24 lg:py-28 xl:py-32 2xl:py-32'
          } `}
        >
          <div className="w-full md:w-6/12">
            {hasHeroBanner && (
              <Fade delay={1}>
                <div className="flex flex-row items-center justify-center">
                  <div className="mb-4 w-auto lg:mb-4 2xl:mb-6">
                    <a
                      href="/llm-studio"
                      className="group relative block rounded-full border border-grey-250 bg-body px-3 py-2.5 font-bold tracking-normal"
                    >
                      <div className="absolute -top-3 left-1/2 -ml-7 w-14 rounded-full bg-green-500 px-1 py-1 text-center text-[10px] uppercase leading-normal text-white lg:-top-3.5 lg:-ml-8 lg:w-16 lg:text-xs">
                        New
                      </div>
                      <div className="-mx-1 flex flex-row items-center text-sm lg:text-base">
                        <div className="w-auto px-1 group-hover:opacity-70">
                          <div className="w-20 lg:w-24">
                            <Logo />
                          </div>
                        </div>
                        <div className="w-auto pr-1 leading-none group-hover:opacity-70">
                          LLM Studio
                        </div>
                        <div className="w-auto px-1 leading-none group-hover:opacity-70">
                          <span className="bg-gradient-to-br from-rm to-pb bg-clip-text text-transparent antialiased">
                            Join the waitlist
                          </span>{' '}
                          ⚡️
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </Fade>
            )}
            <Fade>
              <div className="relative z-20 flex flex-col text-left">
                <h1 className="max-w-xl text-4xl leading-tight text-white md:text-[48px] lg:text-[56px] xl:max-w-2xl xl:text-6xl">
                  <PortableText
                    value={hero._rawTitleV2}
                    components={titlePortableText as any}
                  />
                </h1>
                <div className="mt-6 max-w-[600px] font-light text-white/80 md:text-xl">
                  <PortableText
                    value={hero._rawSubTitleV2}
                    components={subTitlePortableText as any}
                  />
                </div>
                <div className="-mx-2 mt-4 flex w-full flex-wrap justify-start text-center">
                  {ctaLinksV2.map((i, idx) => {
                    if (i._type === 'externalLink') {
                      return (
                        <div
                          className="my-2 w-auto px-2"
                          key={`cta-link-${idx}`}
                        >
                          <OutboundLink
                            href={i.url}
                            onClick={() =>
                              trackEvent({
                                url: i.url,
                              })
                            }
                            className={`relative inline-block w-[186px] rounded-lg border-2 border-primary-600 bg-primary-600 px-6 py-3.5 font-semibold leading-none text-white duration-200 after:pointer-events-none after:absolute after:inset-x-0 after:-bottom-3 after:top-3 after:translate-y-full after:bg-[url(/new-home/button-after.svg)] after:bg-[length:100%_100%] after:bg-center after:bg-no-repeat after:opacity-80 after:content-[''] hover:opacity-80 hover:after:opacity-100`}
                          >
                            {i.label}
                          </OutboundLink>
                        </div>
                      );
                    }

                    return (
                      <div
                        className="my-2 w-auto px-2"
                        key={`internal-link-${idx}`}
                      >
                        <a
                          href={`#${i.slug.current}`}
                          onClick={(e) => {
                            e.preventDefault();
                            smoothScrollTo(i.slug.current.replace('#', ''));
                          }}
                          className="relative inline-block w-[186px] rounded-lg border-2 border-primary-600 bg-primary-600 px-6 py-3.5 font-semibold leading-none text-white duration-200 hover:opacity-80"
                        >
                          {i.label}
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Fade>
          </div>

          <div className="w-full max-w-lg md:w-6/12 md:max-w-3xl lg:max-w-4xl xl:max-w-6xl">
            <VideoItem url={heroVideo.asset.url} />
          </div>

          {/* <Popup
            isDark={true}
            popUpIsOpen={isPopupOpen}
            closePopup={closePopup}
            openPopup={openPopup}
          >
            <div className="container px-6 mx-auto w-full md:max-w-5xl lg:max-w-7xl overflow-y-scroll ">
              <VideoItem url={heroVideo.asset.url} webm={heroVideoWebm.asset.url} />
            </div>
          </Popup> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
