import React from 'react';

import Container from '@/components/common/container';

import { PortableText } from '@portabletext/react';
import { titlePortableText } from '../v4/hero';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';
import { trackEvent } from '@/utils/amplitude-tracking';

const BuiltFor = ({ data }) => {
  return (
    <div className="md:py-26 relative border-y border-black/20 bg-white py-20 pt-[120px]">
      <div className="absolute inset-x-0 top-0 h-1/2 bg-[url('/new-home/built-for-top-bg.svg')] bg-contain bg-no-repeat"></div>
      <div className="absolute inset-x-0 bottom-0 h-1/2 bg-[url('/new-home/built-for-bottom-bg.svg')] bg-contain bg-bottom bg-no-repeat"></div>
      <Container className="relative z-10 space-y-6 lg:px-4">
        <div>
          <h2 className="mb-4 text-center text-[36px] font-medium leading-tight lg:text-[44px]">
            <PortableText
              value={data._rawTitle}
              components={titlePortableText as any}
            />
          </h2>
          <p className="mx-auto max-w-[740px] text-center text-lg">
            {data.subTitle}
          </p>
        </div>

        <div className="-mx-2 mt-10 flex flex-wrap justify-center xl:-mx-4">
          {data.builtForItems.map((i, idx) => {
            return (
              <div
                className="my-8 w-full max-w-sm px-2 md:w-1/3 xl:px-4"
                key={`built-for-${idx}`}
              >
                <div className="space-y-4 px-5 py-5 text-center md:px-4 md:py-4 xl:px-5 xl:py-5">
                  <div className="mx-auto -ml-2 mb-4 flex items-center justify-center">
                    <div className="flex aspect-video w-64 items-center justify-center">
                      <img src={i.image.asset.url} alt={i.title}></img>
                    </div>
                  </div>
                  <p className="mb-4 text-base font-bold lg:text-[18px] xl:text-[24px]">
                    {i.title}
                  </p>
                  <p className="text-smoky-black/80">{i.description}</p>
                  {i.links &&
                    i.links.map((link, idx) => {
                      if (link._type === 'externalLink') {
                        return (
                          <OutboundLink
                            key={`cta-link-${idx}`}
                            href={link.url}
                            onClick={() =>
                              trackEvent({
                                url: link.url,
                              })
                            }
                            className="border-b border-primary-600 text-sm text-primary-600 hover:border-dashed lg:text-base"
                          >
                            <span className="bg-gradient-to-br from-rm via-purpley to-pb bg-clip-text text-transparent antialiased">
                              {link.label}
                            </span>
                          </OutboundLink>
                        );
                      }

                      return (
                        <a
                          href={`/${link.slug.current}`}
                          key={`internal-link-${idx}`}
                          className="border-b border-primary-600 text-sm text-primary-600 hover:border-dashed lg:text-base"
                        >
                          <span className="bg-gradient-to-br from-rm via-purpley to-pb bg-clip-text text-transparent antialiased">
                            {link.label}
                          </span>
                        </a>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default BuiltFor;
