import React, { useEffect, useRef } from 'react';
import Container from '@/components/common/container';
import { PortableText } from '@portabletext/react';
import { titlePortableText } from '../v4/hero';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';
import { trackEvent } from '@/utils/amplitude-tracking';

const DesignedFor = ({ data }) => {
  const words = ['AI Builders', 'Domain Experts', 'Product Managers'];
  const wordSwapRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    let count = 0;
    const changeWord = () => {
      const wordSwap = wordSwapRef.current;
      if (!wordSwap) return;
      if (animationRef.current) {
        while (wordSwap.firstChild) {
          wordSwap.removeChild(wordSwap.firstChild);
        }
      }
      let newDiv = document.createElement('span');
      newDiv.innerHTML = words[count % words.length];
      newDiv.style.position = 'absolute';
      newDiv.style.opacity = '0';
      newDiv.style.transform = 'translateY(-20px)';
      wordSwap.appendChild(newDiv);

      let currentDiv =
        wordSwap.children.length > 1 ? wordSwap.children[0] : null;

      let fadeInUp = newDiv.animate(
        [
          { opacity: '0', transform: 'translateY(-20px)' },
          { opacity: '1', transform: 'translateY(0)' },
        ],
        { duration: 300, fill: 'forwards' },
      );

      animationRef.current = fadeInUp;

      fadeInUp.onfinish = () => {
        setTimeout(() => {
          let fadeOutDown = newDiv.animate(
            [
              { opacity: '1', transform: 'translateY(0)' },
              { opacity: '0', transform: 'translateY(20px)' },
            ],
            { duration: 300, fill: 'forwards' },
          );

          fadeOutDown.onfinish = () => {
            if (currentDiv && wordSwap.contains(currentDiv)) {
              wordSwap.removeChild(currentDiv);
            }
          };
        }, 1500);
      };

      count++;
    };

    let intervalId = setInterval(changeWord, 2400);

    const handleVisibilityChange = () => {
      if (document.hidden) {
        clearInterval(intervalId);
      } else {
        intervalId = setInterval(changeWord, 2400);
        changeWord();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    changeWord();

    return () => {
      clearInterval(intervalId);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <div className="relative overflow-hidden">
      <div
        style={{
          borderRadius: '1462px',
          // background:
          //   'radial-gradient(47.13% 42.99% at 50% 50%, #695EFF 0%, rgba(239, 67, 205, 0.00) 100%)',
        }}
        className="absolute inset-x-[7%] top-0 z-10 h-72 -translate-y-1/2 rounded-full bg-gradient-to-b from-purpley via-rm/50 to-rm/0 opacity-50 blur-3xl"
      ></div>

      <div
        style={{
          borderRadius: '1462px',
          // background:
          //   'radial-gradient(47.13% 42.99% at 50% 50%, #695EFF 0%, rgba(239, 67, 205, 0.00) 100%)',
        }}
        className="absolute inset-x-[7%] bottom-0 z-10 h-72 translate-y-1/2 rounded-full bg-gradient-to-t from-purpley via-rm/50 to-rm/0 opacity-50 blur-3xl"
      ></div>

      <div className="bg-bluey py-20 md:py-[120px]">
        <Container className="lg:px-4">
          {/* <h2 className="text-smoky-black text-[36px] lg:text-[44px] font-medium text-center leading-tight mb-6">
            <PortableText
              value={data._rawTitle}
              components={titlePortableText as any}
            />
          </h2> */}
          <h2 className="mb-6 flex flex-col text-center text-[36px] font-medium leading-tight text-smoky-black md:ml-[-300px] md:block lg:text-[44px]">
            Designed for{' '}
            <span
              ref={wordSwapRef}
              className="relative mx-auto flex h-[55px] w-full justify-center overflow-hidden whitespace-nowrap text-[#695eff] md:ml-[10px] md:mr-0 md:inline"
            ></span>
          </h2>
          <p className="mx-auto mb-14 max-w-[760px] text-center text-lg text-smoky-black/80">
            {data.subTitle}
          </p>

          <div className="grid grid-flow-row-dense grid-cols-1 gap-4 lg:grid-cols-3 xl:gap-8">
            <div className="rounded-lg bg-gradient-to-r from-white to-bluey lg:col-span-2">
              <img
                className="w-full"
                src="/new-home/designed-for-ai.svg"
                alt="Designed for Data Scientists"
              />
            </div>
            <div className="relative rounded-lg border border-purple-200 bg-gradient-to-tr from-white to-bluey lg:col-span-1">
              {/* <img  src="/new-home/designed-for-laptop.svg" alt="Designed for Data Scientists" /> */}
              <div className="absolute inset-0 z-0 bg-[url(/new-home/designed-for-laptop.svg)] bg-[length:60%] bg-right-bottom bg-no-repeat sm:bg-[length:65%] md:bg-[length:45%] lg:bg-[length:85%]"></div>
              <div className="relative z-10 flex h-full flex-col justify-between px-6 py-8 xl:px-12 xl:py-14">
                <div>
                  <h3 className="mb-2 max-w-xs text-xl font-semibold leading-tight lg:text-2xl xl:text-3xl">
                    {data.ctaTitle}
                  </h3>
                  <p className="font-bold">{data.ctaSubTitle}</p>
                </div>

                <div className="my-14 flex space-x-4">
                  <span className="inline-block rounded-full bg-gradient-to-tl from-purpley via-purpley to-rm px-8 py-4 font-mono text-xl font-semibold text-white xl:px-11 xl:py-5 xl:text-2xl">
                    pip install
                  </span>

                  {/* {data.links &&
                    data.links.map((link, idx) => {
                      if (link._type === 'externalLink') {
                        return (
                          <OutboundLink
                            key={`cta-link-${idx}`}
                            href={link.url}
                            onClick={() =>
                              trackEvent({
                                url: link.url,
                              })
                            }
                            className="font-mono text-xl xl:text-2xl bg-gradient-to-tl hover:from-rm hover:to-purpley from-purpley via-purpley to-rm xl:px-11 px-8 rounded-full py-4 xl:py-5 inline-block text-white font-semibold"
                          >
                            {link.label}
                          </OutboundLink>
                        );
                      }

                      return (
                        <a
                          href={`/${link.slug.current}`}
                          key={`internal-link-${idx}`}
                          className="font-mono text-xl xl:text-2xl bg-gradient-to-tl hover:from-rm hover:to-purpley from-purpley via-purpley to-rm xl:px-11 px-8 rounded-full py-4 xl:py-5 inline-block text-white font-semibold"
                        >
                          {link.label}
                        </a>
                      );
                    })} */}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default DesignedFor;
