import React, { useState, useEffect } from 'react';

import Container from '@/components/common/container';
import Fade from '@/components/common/fade';
import { PortableText } from '@portabletext/react';
import { titlePortableText } from '../v4/hero';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';
import { trackEvent } from '@/utils/amplitude-tracking';
import CheckIcon from '@/assets/check-mark.svg';
import Lottie from 'react-lottie';

import {
  useInView,
  InView,
  ObserverInstanceCallback,
} from 'react-intersection-observer';

import * as animOne from '@/assets/Animation_1.json';
import * as animTwo from '@/assets/Animation_2.json';
import * as animThree from '@/assets/Animation_3.json';
import * as animFour from '@/assets/Animation_4_5.json';

const sanityToken = process.env.SANITY_TOKEN;

const AnimationComponent = ({ i }) => {
  const [animationJson, setAnimationJson] = useState(null);

  useEffect(() => {
    try {
      fetch(i.animation.asset.url, {
        method: 'GET', // Replace with the desired HTTP method
        headers: {
          Authorization: `Bearer ${sanityToken}}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          setAnimationJson(json);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <Lottie
      options={{
        animationData: animationJson,
        path: i.animation.asset.url,
        loop: true,
        autoplay: true,
      }}
    />
  );
};

const MeetTextColumn = ({ i }) => {
  return (
    <Fade>
      <div className="-mx-2 mb-2 flex flex-row items-center">
        <div className="w-auto px-2">
          <div className="flex h-8 w-8 items-center justify-center xl:h-10 xl:w-10">
            <img src={i.icon.asset.url}></img>
          </div>
        </div>
        <div className="w-auto px-2">
          {i.title && (
            <h3 className="text-xl font-semibold text-dark-80 lg:text-2xl xl:text-3xl">
              {i.title}
            </h3>
          )}
        </div>
      </div>
      <p className="text-lg lg:text-xl xl:text-2xl">
        {' '}
        <span className="">{i.subTitle}</span>
      </p>
      <p className="description-hl my-5 text-base text-smoky-black/80 lg:my-7 xl:text-lg">
        {i.text}
      </p>
      <ListItems items={i.listItems} />

      {i.links &&
        i.links.map((link, idx) => {
          if (link._type === 'externalLink') {
            return (
              <OutboundLink
                key={`cta-link-${idx}`}
                href={link.url}
                onClick={() =>
                  trackEvent({
                    url: link.url,
                  })
                }
                className="border-b border-primary-600 text-sm text-primary-600 hover:border-dashed lg:text-base"
              >
                {link.label}
              </OutboundLink>
            );
          }

          return (
            <a
              href={`/${link.slug.current}`}
              key={`internal-link-${idx}`}
              className="border-b border-primary-600 text-sm text-primary-600 hover:border-dashed lg:text-base"
            >
              {link.label}
            </a>
          );
        })}
    </Fade>
  );
};

const FirstSection = () => {
  return (
    <div className="relative aspect-square">
      <img className="w-full max-w-full" src="/new-home/meet/prompt.svg " />
      <img
        src="/new-home/meet/prompt-sm.svg"
        className="absolute -right-[5%] bottom-[15%] w-7/12"
      />
    </div>
  );
};

const SecondSection = () => {
  return (
    <div className="relative aspect-square">
      <img
        className="w-10/12 max-w-full rounded-[25px] lg:w-11/12"
        src="/new-home/meet/fine-tune.svg "
      />
      <img
        src="/new-home/meet/fine-tune-2.svg"
        className="absolute -right-[2%] top-[10%] w-6/12 rounded-2xl shadow-2xl lg:-right-[5%]"
      />
      <img
        src="/new-home/meet/fine-tune-3.svg"
        className="absolute -bottom-[1%] -right-[3%] w-5/12 lg:-right-[8%]"
      />
    </div>
  );
};

const ThirdSection = () => {
  return (
    <div className="relative aspect-4/3">
      <img
        className="w-full max-w-full rounded-[25px]"
        src="/new-home/meet/monitor.svg"
      />
    </div>
  );
};

const ListItems = ({ items }) => {
  return (
    <ul className="my-7">
      {items.map((item, idx) => (
        <li className="-mx-1 my-3 flex flex-row" key={`list-item-${idx}`}>
          <div className="w-auto px-1">
            <div className="flex h-5 w-5 items-center justify-center">
              <CheckIcon />
            </div>
          </div>
          <div className="w-auto px-1 text-smoky-black/60">
            <span>{item}</span>
          </div>
        </li>
      ))}
    </ul>
  );
};

const howSectionItems = [
  {
    title: 'Prompt',
    subtitle: 'Accelerate Prompt Engineering',
    description:
      '<p>Stop managing prompt runs in notebooks and spreadsheets. Instead take a metrics-driven approach and build prompts that just work.</p>',

    list: [
      'Evaluate prompts and LLM  outputs algorithmically',
      'Collaboratively build and test prompts',
    ],

    imageComponent: (
      <Lottie
        options={{
          animationData: animOne,
          loop: true,
          autoplay: true,
        }}
      />
    ),
    link: {
      text: 'Read our docs',
      href: 'https://rungalileo.gitbook.io/galileo/',
    },
  },
  {
    title: 'Fine-tune',
    subtitle: 'Fine-Tune with the Right Data',
    description:
      '<p>Quickly find the perfect context and data for your LLM. </p>',

    list: [
      'Find and fix data hurting model performance',
      'AI-assisted evaluation',
    ],
    imageComponent: (
      <Lottie
        options={{
          animationData: animTwo,
          loop: true,
          autoplay: true,
        }}
      />
    ),
    link: {
      text: 'Read our docs',
      href: 'https://rungalileo.gitbook.io/galileo/',
    },
  },
  {
    title: 'Monitor',
    subtitle: 'Monitor LLM Outputs',
    description:
      '<p>Rather than reacting when its too late, proactively detect hallucinations in production.</p>',

    list: ['Define LLM guardrails', 'Get proactive alerts and notifications'],
    imageComponent: (
      <Lottie
        options={{
          animationData: animThree,
          loop: true,
          autoplay: true,
        }}
      />
    ),
    link: {
      text: 'Read our docs',
      href: 'https://rungalileo.gitbook.io/galileo/',
    },
  },
  {
    title: 'Monitor',
    subtitle: 'Monitor LLM Outputs',
    description:
      '<p>Rather than reacting when its too late, proactively detect hallucinations in production.</p>',

    list: ['Define LLM guardrails', 'Get proactive alerts and notifications'],
    imageComponent: (
      <Lottie
        options={{
          animationData: animFour,
          loop: true,
          autoplay: true,
        }}
      />
    ),
    link: {
      text: 'Read our docs',
      href: 'https://rungalileo.gitbook.io/galileo/',
    },
  },
];

const Meet = ({ data }) => {
  return (
    <div className="bg-white py-20 md:py-28 xl:py-[96px]">
      <Container className="pt-6 lg:px-4">
        <h2 className="mx-auto mb-4 flex justify-center text-center text-[36px] font-medium leading-tight lg:text-[44px]">
          <div className="relative before:-translate-y-full before:opacity-80 hover:before:opacity-100">
            <PortableText
              value={data._rawTitle}
              components={titlePortableText as any}
            />
          </div>
        </h2>

        <p className="mb-12 text-center text-lg font-normal not-italic leading-[130%] text-[#070707]">
          An end-to-end platform for GenAI evaluation, experimentation, and
          observability
        </p>

        <div className="space-y-6">
          {data.meetSections.map((i, idx) => {
            const { ref, inView } = useInView({
              triggerOnce: false,
              threshold: 1,
              rootMargin: '100px 0px 50% 0px',
            });
            if (idx % 2 === 0) {
              return (
                <div
                  key={`even-${idx}`}
                  className="rounded-lg border border-purple-100 bg-gradient-to-tr from-white via-white/50 to-bluey px-4 py-8 xl:p-12 2xl:p-16"
                >
                  <div className="-mx-4 flex flex-wrap items-center justify-between">
                    <div
                      ref={ref}
                      className={`w-full px-4 md:w-5/12 lg:w-5/12 ${data.meetSections.length - 1 === idx ? 'xl:w-3/12' : 'lg:pr-10 xl:pr-24'}`}
                    >
                      <MeetTextColumn i={i} />
                    </div>
                    <div
                      className={`clear-svg mt-7 w-full px-4 md:mt-0 md:w-6/12 ${data.meetSections.length - 1 === idx ? 'xl:w-9/12' : 'xl:w-7/12'} lg:shrink`}
                    >
                      <AnimationComponent i={i} />
                    </div>
                  </div>
                </div>
              );
            }
            return (
              <div
                key={`odd-${idx}`}
                className="rounded-lg border border-purple-100 bg-gradient-to-tl from-white via-white/50 to-bluey px-4 py-8 xl:p-12 2xl:p-16"
              >
                <div className="-mx-4 flex flex-wrap-reverse items-center justify-between md:flex-wrap">
                  <div className="mt-7 w-full px-4 md:mt-0 md:w-6/12 lg:shrink xl:w-7/12">
                    <AnimationComponent i={i} />
                  </div>
                  <div
                    ref={ref}
                    className="w-full px-4 md:w-5/12 lg:w-5/12 lg:pl-10 xl:pl-24"
                  >
                    <MeetTextColumn i={i} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default Meet;
